import * as React from 'react'
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Breadcrumb, StyledDataGrid } from '../../common/components'
import { Link, useParams } from 'react-router-dom'
import { type GridSortModel, type GridColDef } from '@mui/x-data-grid'
import {
  NoteApi,
  type NoteDto,
  SystemApi,
  type SystemDto,
  ReportApi,
  type ReportInfoDto,
  ProjectDocumentApi,
  type ProjectDocumentDto,
  ProjectApi
} from '../../common/services'
import { useQuery } from '@tanstack/react-query'
import pdf from '../../assets/images/mc-file-pdf.svg'
import unKnown from '../../assets/images/mc-file-unknown.svg'
import sheet from '../../assets/images/mc-file-spreadsheet.svg'
// eslint-disable-next-line import/no-duplicates
import png from '../../assets/images/mc-file-image.svg'
// eslint-disable-next-line import/no-duplicates
import jpeg from '../../assets/images/mc-file-image.svg'
import plain from '../../assets/images/mc-file-text.svg'
import document from '../../assets/images/mc-file-document.svg'
import ContainerModal from './components/modalContainer'
import AlertDelete from '../../common/components/AlertDelete'
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ContainerModelReport, NoteModal } from './components'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../common/KeyTranslation.json'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { v4 as uuidv4 } from 'uuid'

const icons: Record<string, string> = {
  pdf,
  unKnown,
  sheet,
  png,
  jpeg,
  document,
  plain
}
export default function ProjectDetail (): JSX.Element {
  const { t } = useTranslation()
  const id = useParams()
  const projectId = Number(id.id)
  const [visible, setVisible] = React.useState(false)
  const [visibleReport, setVisibleReport] = React.useState(false)
  const [noteModalProps, setNoteModalProps] = React.useState<{
    visible: boolean
    data?: NoteDto
  }>({
    visible: false,
    data: undefined
  })
  const [dialogOpen, setDialogOpen] = React.useState({
    open: false,
    id: 0
  })
  const [sortBy, setSortBy] = React.useState({
    sortField: '',
    isDescending: true
  })
  const handleSortModelChange = (sortModel: GridSortModel): void => {
    if (sortModel != null && sortModel.length > 0) {
      const transformedSortModel = sortModel.map((item) => ({
        sortField: item.field,
        isDescending: item.sort === 'desc' ? true : item.sort === 'asc' ? false : !sortBy.isDescending
      }))
      const [firstSortModel] = transformedSortModel
      setSortBy(firstSortModel)
      setPaginationModel({
        page: 0,
        pageSize: paginationModel.pageSize
      })
    }
  }
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5
  })
  const [paginationNote, setPaginationNote] = React.useState({
    page: 0,
    pageSize: 3
  })
  const [paginationReport, setPaginationReport] = React.useState({
    page: 0,
    pageSize: 3
  })
  const [paginationDocument, setPaginationDocument] = React.useState({
    page: 0,
    pageSize: 3
  })
  // const [paginationRevisionHistory, setPaginationRevisionHistory] =
  //   React.useState({
  //     page: 0,
  //     pageSize: 5
  //   })
  const {
    data: systemData,
    isLoading: systemIsLoading,
    isFetching: systemIsFetching,
    refetch: systemRefetch
  } = useQuery(
    ['/api/v2/System/SearchSystemsByPaging', projectId, paginationModel],
    async () => {
      if (projectId !== undefined) {
        const { data } =
          await new SystemApi().apiV2SystemSearchSystemsByPagingGet(
            projectId,
            paginationModel.page + 1,
            paginationModel.pageSize,
            undefined,
            sortBy.sortField

          )
        return data
      }
    },
    { enabled: false }
  )
  const {
    data: noteData,
    isLoading: noteIsLoading,
    isFetching: noteIsFetching,
    refetch: noteRefetch
  } = useQuery(
    ['/api/v2/Note/SearchNoteByPaging', projectId, paginationNote],
    async () => {
      if (projectId !== undefined) {
        const { data } = await new NoteApi().apiV2NoteSearchNoteByPagingGet(
          projectId,
          paginationNote.page + 1,
          paginationNote.pageSize
        )
        return data
      }
    },
    { enabled: false }
  )

  const {
    data: reportData,
    isLoading: reportIsLoading,
    isFetching: reportIsFetching,
    refetch: reportRefetch
  } = useQuery(
    ['/api/v2/Report/SearchReportByPaging', projectId, paginationReport],
    async () => {
      if (projectId !== undefined) {
        const { data } =
          await new ReportApi().apiV2ReportSearchReportByPagingGet(
            projectId,
            paginationReport.page + 1,
            paginationReport.pageSize
          )
        return data
      }
    },
    { enabled: false }
  )

  const {
    data: projectDocumentData,
    isLoading: projectDocumentIsLoading,
    isFetching: projectDocumentIsFetching,
    refetch: projectDocumentRefetch
  } = useQuery(
    [
      '/api/v2/ProjectDocument/ProjectAndProductDocuments/{projectId}',
      projectId
    ],
    async () => {
      if (projectId !== undefined) {
        const { data } =
          await new ProjectDocumentApi().apiV2ProjectDocumentProjectAndProductDocumentsProjectIdGet(
            projectId
          )
        return data
      }
    },
    { enabled: false }
  )

  // const {
  //   data: revisionHistoryData,
  //   isLoading: revisionHistoryIsLoading,
  //   isFetching: revisionHistoryIsFetching,
  //   refetch: revisionHistoryRefetch
  // } = useQuery(
  //   [
  //     '/api/v2/RevisionHistory/GetRevisionPaging',
  //     projectId,
  //     paginationRevisionHistory
  //   ],
  //   async () => {
  //     if (projectId !== undefined) {
  //       const { data } =
  //         await new RevisionHistoryApi().apiV2RevisionHistoryGetRevisionPagingGet(
  //           projectId,
  //           paginationRevisionHistory.page + 1,
  //           paginationRevisionHistory.pageSize
  //         )
  //       return data
  //     }
  //   },
  //   { enabled: false }
  // )
  const project = useQuery(
    ['/api/v2/Project/GetProjectById', projectId],
    async () => {
      const { data } = await new ProjectApi().apiV2ProjectGetProjectByIdGet(
        projectId
      )
      return data.data
    },
    { enabled: false }
  )
  React.useEffect(() => {
    void project.refetch()
  }, [])
  React.useEffect(() => {
    void systemRefetch()
  }, [paginationModel, sortBy])
  React.useEffect(() => {
    void noteRefetch()
  }, [paginationNote])
  React.useEffect(() => {
    void reportRefetch()
  }, [paginationReport])
  React.useEffect(() => {
    void projectDocumentRefetch()
  }, [paginationDocument])
  // React.useEffect(() => {
  //   void revisionHistoryRefetch()
  // }, [paginationRevisionHistory])
  const handleDialogClose = React.useCallback(() => {
    setDialogOpen({
      open: false,
      id: 0
    })
  }, [])
  const handlePaginationChange = (newPagination: any): void => {
    setPaginationDocument(newPagination)
    void projectDocumentRefetch()
  }
  const handleDelete = React.useCallback(
    async (id: number) => {
      try {
        if (id != null) {
          await new SystemApi().apiV2SystemIdDelete(id)
          await systemRefetch()
          handleDialogClose()
          toast.success(t(KeyTranslation.Message_Delete_Success))
        }
      } catch (ex: any) {
        handleDialogClose()
        console.log(ex)
      }
    },
    [systemRefetch, handleDialogClose]
  )
  const handleCopyToClipboard = (): void => {
    toast.success(t(KeyTranslation.Copy_Success))
  }
  const handleDuplicate = async (id: number): Promise<void> => {
    const { data: systemDataDetail } = await new SystemApi().apiV2SystemIdGet(id)
    const updatedSystem = { ...systemDataDetail?.data }
    if (updatedSystem.legs != null) {
      updatedSystem.legs = updatedSystem.legs.map((leg) => {
        const { id, ...legWithoutId } = leg
        if (legWithoutId.spans != null) {
          legWithoutId.spans = legWithoutId.spans.map((span) => {
            const { id, ...spanWithoutId } = span
            return spanWithoutId
          })
        }
        return legWithoutId
      })
    }
    const randomreference = uuidv4()

    try {
      const updatedSystemData = {
        ...updatedSystem,
        reference: randomreference
      }

      await new SystemApi().apiV2SystemPost(true, updatedSystemData)
      void systemRefetch()
      toast.success(t(KeyTranslation.Message_Duplicate_success))
    } catch (ex: any) {
      toast.error(t(ex.response.data.error))
    }
  }

  const columnsSystem: Array<GridColDef<SystemDto>> = [
    {
      field: 'name',
      headerName: t(KeyTranslation.Common_System_name) ?? '',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={row.name}>
            <Link to={`/projects/${projectId ?? 0}/system/${row.id ?? 0}`}>
              {row.name}
            </Link>
          </Tooltip>
        )
      }
    },
    {
      field: 'applicationLocation',
      headerName: t(KeyTranslation.System_Popup_Application_location) ?? '',
      width: 200
    },
    // {
    //   field: 'reference',
    //   headerName: t(KeyTranslation.Header_Reference) ?? '',
    //   flex: 1
    // },
    {
      field: 'systemTypeName',
      headerName: t(KeyTranslation.Header_Type) ?? ''
    },
    {
      field: 'lastUpdatedBy',
      headerName: t(KeyTranslation.Header_LastUpdatedBy) ?? '',
      width: 150
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: 200,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" gap={3} alignItems={'center'}>
            <Tooltip title={t(KeyTranslation.Common_SystemOverview)}>
              <Link
                to={`/projects/${projectId ?? 0}/system-overview/${row.id ?? 0
                  }`}
              >
                <IconButton>
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title={t(KeyTranslation.Btn_Edit)}>
              <IconButton>
                <Link
                  className="link"
                  to={`/projects/${projectId}/system/${row.id ?? 0}`}
                >
                  <EditOutlinedIcon />
                </Link>
              </IconButton>
            </Tooltip>
            <Tooltip title={t(KeyTranslation.Common_Duplicate)}>
              <IconButton onClick={() => {
                void handleDuplicate(Number(row.id))
              }}>
                  <ContentCopyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t(KeyTranslation.Btn_Delete)}>
              <IconButton
                onClick={() => {
                  setDialogOpen({
                    open: true,
                    id: row.id ?? 0
                  })
                }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )
      }
    }
  ]
  const columnsDummyNote: Array<GridColDef<NoteDto>> = [
    {
      field: 'title',
      headerName: 'Name',
      minWidth: 200,
      flex: 1
    }
  ]
  const columnsDummyReport: Array<GridColDef<ReportInfoDto>> = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 200,
      flex: 1
    }
  ]
  const columnsDummyProjectDocument: Array<GridColDef<ProjectDocumentDto>> = [
    {
      field: 'blobUrl',
      headerName: 'BlobUrl',
      width: 50,
      renderCell: ({ row }) => {
        const fileType = row.type?.split('/').pop()?.toLowerCase()
        const iconUrl = fileType === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ? icons.sheet
          : fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document'
            ? icons.document
            : icons[fileType ?? 'unKnown'] ?? icons.unKnown
        return (
          <>
            <img
              width={'100%'}
              height={30}
              style={{ objectFit: 'contain' }}
              src={iconUrl}
              alt={row.name ?? ''}
            />
          </>
        )
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 200,
      flex: 1
    }
  ]
  // const columnsRevision: Array<GridColDef<RevisionHistoryDto>> = [
  //   {
  //     field: 'action',
  //     headerName: t(KeyTranslation.Action) ?? '',
  //     flex: 1,
  //     renderCell: ({ row }) => {
  //       return <div>{t(`${row.action ?? ''}`)}</div>
  //     }
  //   },
  //   {
  //     field: 'createdDate',
  //     headerName: t(KeyTranslation.Common_Date) ?? '',
  //     flex: 1,
  //     renderCell: ({ row }) => {
  //       const inputFormat = 'YYYY-MM-DDTHH:mm:ss.SSS'
  //       const outputFormat = 'YYYY-MM-DD HH:mm'
  //       const convertedTime = moment(row.createdDate, inputFormat).format(
  //         outputFormat
  //       )
  //       return <div>{convertedTime}</div>
  //     }
  //   },
  //   {
  //     field: 'editedBy',
  //     headerName: t(KeyTranslation.User) ?? '',
  //     flex: 1
  //   }
  // ]
  return (
    <Box>
      <Grid container spacing={2} mb={5} alignItems="center">
        <Grid xs={4}>
          <Typography variant="h1">{project.data?.projectName}</Typography>
        </Grid>
        <Grid display={'flex'} flexDirection={'column'} xs={4}>
          <Typography component={'span'} variant="caption">
            {t(KeyTranslation.Common_ProjectRef)}:{' '}
          </Typography>
          <Typography component={'span'}>{project.data?.reference}</Typography>
        </Grid>
        <Grid xs={4}>
          <Typography component={'span'} variant="caption">
            {t(KeyTranslation.Common_ProjectUrl)}:{' '}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography component={'span'}>{window.location.href}</Typography>
            <CopyToClipboard
              onCopy={handleCopyToClipboard}
              text={window.location.href}
            >
              <Button size="small">{t(KeyTranslation.Common_Copy)}</Button>
            </CopyToClipboard>
          </Box>
        </Grid>
      </Grid>
      <Breadcrumbs aria-label="breadcrumb">
        <Breadcrumb
          component="div"
          label={<Link to={'/'}>{t(KeyTranslation.Common_ProjectsDashboard)}</Link>}
        />
        <Breadcrumb component="div" label={t(KeyTranslation.Common_ProjectOverview)} />
      </Breadcrumbs>
      <Box sx={{ bgcolor: 'white', mt: 5 }} className="main">
        <Box className="tableTools">
          <Typography variant="h4">{t(KeyTranslation.Menu_Systems)}</Typography>
          <Box display="flex" gap={3}>
            <Button
              variant="contained"
              onClick={() => {
                setVisible(true)
              }}
            >
              {t(KeyTranslation.Common_New_System)}
            </Button>
          </Box>
        </Box>
        <Box className="heightBox">
          <StyledDataGrid
            autoHeight
            loading={systemIsLoading || systemIsFetching}
            rows={systemData?.data != null ? systemData.data : []}
            columns={columnsSystem}
            rowSelection={false}
            disableColumnMenu={true}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            getRowId={(row) => row.id}
            rowCount={systemData?.totals ?? 0}
            paginationModel={paginationModel}
            onSortModelChange={(sortModel) => { handleSortModelChange(sortModel) }}
          />
        </Box>
      </Box>
      <Grid container spacing={4} alignItems="start">
        <Grid xs={12} md={4}>
          <Box sx={{ bgcolor: 'white', mt: 5 }} className="main">
            <Box className="tableTools">
              <Typography variant="h4">{t(KeyTranslation.Note_Notes)}</Typography>
              <Box>
                <Tooltip title={t(KeyTranslation.Common_Viewall)}>
                  <Link className="link" to={`/projects/${projectId}/notes`}>
                    <IconButton>
                      <AspectRatioOutlinedIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title={t(KeyTranslation.Note_New_Note)}>
                  <IconButton
                    onClick={() => {
                      setNoteModalProps({
                        visible: true,
                        data: undefined
                      })
                    }}
                  >
                    <AddOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box className="scrollableBox">
              <StyledDataGrid
                autoHeight
                loading={noteIsLoading || noteIsFetching}
                rows={noteData?.data ?? []}
                columns={columnsDummyNote}
                rowSelection={false}
                disableColumnMenu={true}
                paginationMode="server"
                onPaginationModelChange={setPaginationNote}
                getRowId={(row) => row.id}
                rowCount={noteData?.totals ?? 0}
                paginationModel={paginationNote}
                slots={{
                  columnHeaders: () => null
                }}
                rowHeight={44}
              />
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={4}>
          <Box sx={{ bgcolor: 'white', mt: 5 }} className="main">
            <Box className="tableTools">
              <Typography variant="h4">{t(KeyTranslation.Common_Report_Reports)}</Typography>
              <Box>
                <Tooltip title={t(KeyTranslation.Common_Viewall)}>
                  <Link className="link" to={`/projects/${projectId}/reports`}>
                    <IconButton>
                      <AspectRatioOutlinedIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title={t(KeyTranslation.New_Report)}>
                  <IconButton
                    onClick={() => {
                      setVisibleReport(true)
                    }}
                  >
                    <AddOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box className="scrollableBox">
              <StyledDataGrid
                autoHeight
                loading={reportIsLoading || reportIsFetching}
                rows={reportData?.data ?? []}
                columns={columnsDummyReport}
                rowSelection={false}
                disableColumnMenu={true}
                paginationMode="server"
                onPaginationModelChange={setPaginationReport}
                getRowId={(row) => row.id}
                rowCount={reportData?.totals ?? 0}
                paginationModel={paginationReport}
                slots={{
                  columnHeaders: () => null
                }}
                rowHeight={44}
              />
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={4}>
          <Box sx={{ bgcolor: 'white', mt: 5 }} className="main">
            <Box className="tableTools">
              <Typography variant="h4">{t(KeyTranslation.Document_Documents)}</Typography>
              <Box>
                <Tooltip title={t(KeyTranslation.Common_Viewall)}>
                  <Link
                    className="link"
                    to={`/projects/${projectId}/documents`}
                  >
                    <IconButton>
                      <AspectRatioOutlinedIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title={t(KeyTranslation.New_Document)}>
                  <IconButton>
                    <Link
                      className="link"
                      to={`/projects/${projectId}/documents`}
                    >
                      <AddOutlinedIcon />
                    </Link>
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box className="scrollableBox">
              <StyledDataGrid
                autoHeight
                loading={projectDocumentIsLoading || projectDocumentIsFetching}
                rows={projectDocumentData?.data ?? []}
                columns={columnsDummyProjectDocument}
                rowSelection={false}
                disableColumnMenu={true}
                paginationMode="client"
                onPaginationModelChange={handlePaginationChange}
                getRowId={(row) => row.id}
                rowCount={projectDocumentData?.data?.length}
                paginationModel={paginationDocument}
                slots={{
                  columnHeaders: () => null
                }}
                rowHeight={44}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <Box sx={{ bgcolor: 'white', mt: 5 }} className="main">
        <Box className="tableTools">
          <Typography variant="h4">{t(KeyTranslation.Pro_Revision_History)}</Typography>
          <Tooltip title={t(KeyTranslation.Common_Viewall)}>
            <Link className="link" to={`/projects/${projectId}/revisions`}>
              <IconButton>
                <AspectRatioOutlinedIcon />
              </IconButton>
            </Link>
          </Tooltip>
        </Box> */}
      {/* <Box className="heightBox">
          <StyledDataGrid
            autoHeight
            loading={revisionHistoryIsLoading || revisionHistoryIsFetching}
            rows={revisionHistoryData?.data ?? []}
            columns={columnsRevision}
            rowSelection={false}
            disableColumnMenu={true}
            paginationMode="server"
            onPaginationModelChange={setPaginationRevisionHistory}
            getRowId={(row) => row.revision}
            rowCount={revisionHistoryData?.totals ?? 0}
            paginationModel={paginationRevisionHistory}
          />
        </Box> */}
      {/* </Box> */}
      {visible && (
        <ContainerModal
          open={visible}
          onClose={() => {
            setVisible(false)
          }}
          systemRefetch={systemRefetch}
          unitId={project.data?.unitId}
          dataRroject={project.data}
        // revisionHistoryRefetch={revisionHistoryRefetch}
        />
      )}
      {visibleReport && (
        <ContainerModelReport
          open={visibleReport}
          onClose={() => {
            setVisibleReport(false)
          }}
          reportRefetch={reportRefetch}
        // revisionHistoryRefetch={revisionHistoryRefetch}
        />
      )}
      <AlertDelete
        handleDelete={() => {
          void handleDelete(dialogOpen.id)
        }}
        dialogOpen={dialogOpen.open}
        handleDialogClose={handleDialogClose}
      />
      {noteModalProps.visible && (
        <NoteModal
          open={noteModalProps.visible}
          // revisionHistoryRefetch={revisionHistoryRefetch}
          onClose={(): void => {
            setNoteModalProps({
              visible: false,
              data: undefined
            })
          }}
          noteRefetch={noteRefetch}
        />
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  )
}
