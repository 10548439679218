import * as React from 'react'
import {
  CssBaseline,
  Drawer as MuiDrawer,
  Box,
  AppBar as MuiAppBar,
  type AppBarProps as MuiAppBarProps,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
  Button,
  Typography,
  Menu,
  MenuItem
} from '@mui/material'
import { Link, Outlet, matchPath, useLocation } from 'react-router-dom'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined'
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined'
import SouthAmericaOutlinedIcon from '@mui/icons-material/SouthAmericaOutlined'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import localStorage from '../common/localStorage'
import { useQuery } from '@tanstack/react-query'
import { LanguageApi, OAuthApi, type LanguageDto } from '../common/services'
import us from '../assets/images/us.png'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import KeyTranslation from '../common/KeyTranslation.json'
import HelpModal from './components/modalHelp'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'
import FeedbackModal from './components/modalFeedback'

const drawerWidth: number = 240
const ITEM_HEIGHT = 48

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(open === true && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`
  })
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: 'border-box',
    ...(open === false && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(12),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(13)
      }
    })
  }
}))

function MainContent (): JSX.Element {
  const [open, setOpen] = React.useState(true)
  const toggleDrawer = (): void => {
    setOpen(!open)
  }
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const loginStore = localStorage.getItem('LOGIN')
  const [helpModalProps, setHelpModalProps] = React.useState<{ visible: boolean }>({
    visible: false
  })
  const [feedbackModalProps, setFeedbackModalProps] = React.useState<{ open: boolean }>({
    open: false
  })
  const has3MAdmin: boolean = (loginStore != null) ? loginStore.userRoles.some((role: { roleId: string, roleName: string }) => role.roleName === '3MAdmin') : false
  const listLanguages = useQuery(
    ['/api/v2/Language'],
    async () => {
      const { data } = await new LanguageApi().apiV2LanguageGet()
      return data
    },
    { enabled: false }
  )
  React.useEffect(() => {
    void listLanguages.refetch()
    const language = localStorage.getItem('LANGUAGE')
    if (language != null && language.code !== '') {
      void i18next.changeLanguage(language.code)
      setSelectedLanguge({
        code: language.code,
        flag: language.flag
      })
    } else {
      void i18next.changeLanguage('en-US')
      setSelectedLanguge({
        code: 'en-US',
        flag: us
      })
    }
  }, [])
  const [selectedLanguge, setSelectedLanguge] = React.useState<LanguageDto>()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openLanguage = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const isLanguageDto = (value: any): value is LanguageDto => {
    return value !== '' && typeof value === 'object' && 'code' in value
  }
  const handleClose = (value: any): void => {
    if (isLanguageDto(value)) {
      setAnchorEl(null)
      void i18next.changeLanguage(value.code ?? '')
      setSelectedLanguge(value)
      localStorage.setItem('LANGUAGE', value)
    } else {
      setAnchorEl(null)
    }
  }
  const [profile, setProfile] = React.useState<null | HTMLElement>(null)
  const openProfile = Boolean(profile)
  const handleClickProfile = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setProfile(event.currentTarget)
  }
  const handleCloseProfile = (): void => {
    setProfile(null)
  }
  const logoutUser = async (): Promise<void> => {
    try {
      await new OAuthApi().apiV2OAuthLogoutGet()
      localStorage.clear()
      window.location.href = process.env.REACT_APP_LOGIN_URL ?? ''
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ display: 'flex', height: 'calc(100vh - 54px)' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} color="default">
          <Box
            sx={{
              px: 5,
              py: 3,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              minHeight: '64px'
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{ ml: '-10px' }}
            >
              <MenuOutlinedIcon />
            </IconButton>
            <Box display="flex" alignItems="center" gap={4}>
              <Box
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                sx={{ height: '22px' }}
                onClick={handleClick}
              >
                <img width={'25px'} src={selectedLanguge?.flag ?? us} alt="" />
                <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon>
              </Box>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button'
                }}
                sx={{ mt: 2 }}
                anchorEl={anchorEl}
                open={openLanguage}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch'
                  }
                }}
              >
                {Array.isArray(listLanguages?.data?.data) &&
                  listLanguages?.data?.data.map((language) => (
                    <MenuItem
                      sx={{ display: 'flex', gap: 2 }}
                      key={language.id}
                      onClick={() => {
                        handleClose(language)
                      }}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={language.flag ?? ''}
                        alt=""
                      />
                      {language.name}
                    </MenuItem>
                  ))}
              </Menu>
              <IconButton color="inherit" onClick={() => {
                setHelpModalProps({
                  visible: true
                })
              }}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
              <IconButton color="inherit" onClick={() => {
                setFeedbackModalProps({
                  open: true
                })
              }}>
                <QuestionAnswerOutlinedIcon />
              </IconButton>
              <Button
                onClick={handleClickProfile}
                color="inherit"
                startIcon={<AccountCircleOutlinedIcon sx={{ fontSize: 22 }} />}
              >
                {(loginStore != null) ? loginStore.userName : ''}
              </Button>
              <Menu
                anchorEl={profile}
                open={openProfile}
                onClose={handleCloseProfile}
              >
                <MenuItem onClick={() => { void logoutUser() }}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>
          {helpModalProps.visible &&
            <HelpModal open={helpModalProps.visible} onClose={(): void => {
              setHelpModalProps({
                visible: false
              })
            }} />
          }
          {feedbackModalProps.open &&
            <FeedbackModal open={feedbackModalProps.open} onClose={(): void => {
              setFeedbackModalProps({
                open: false
              })
            }} />
          }
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: [1]
            }}
          >
            {open && (
              <img
                src={require('../assets/images/3M_Active Safety_RGB_H43.png')}
                alt="logo"
                height={36}
              />
            )}
          </Toolbar>
          {open && <Divider />}
          <List sx={{ py: 5 }} component="nav">
            <ListItemButton
              component={Link}
              to="/"
              selected={matchPath({ path: '/' }, pathname) !== null}
            >
              <ListItemIcon>
                <AccountTreeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={t(KeyTranslation.Common_ProjectsManagement)} />
            </ListItemButton>
            {/* <ListItemButton
              component={Link}
              to="/projects"
              selected={matchPath({ path: '/projects/*' }, pathname) !== null}
            >
              <ListItemIcon>
                <AccountTreeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={t(KeyTranslation.Common_ProjectsManagement)} />
            </ListItemButton> */}
            {open && has3MAdmin && (
              <ListSubheader
                sx={{ textTransform: 'uppercase' }}
                component="div"
              >
                {t(KeyTranslation.Menu_Settings)}
              </ListSubheader>
            )}
            {has3MAdmin && (
              <>
                <ListItemButton
                  component={Link}
                  to="/languages"
                  selected={matchPath({ path: '/languages' }, pathname) !== null}
                >
                  <ListItemIcon>
                    <LanguageOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t(KeyTranslation.Common_Languages)} />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/translations"
                  selected={matchPath({ path: '/translations' }, pathname) !== null}
                >
                  <ListItemIcon>
                    <TranslateOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t(KeyTranslation.Menu_TraslatedLanguages)} />
                </ListItemButton>
                {/* <ListItemButton component={Link} to="/organizations" selected={matchPath({ path: '/organizations' }, pathname) !== null}>
                <ListItemIcon>
                  <ApartmentOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t('Menu_Organisations')} />
              </ListItemButton> */}
                <ListItemButton
                  component={Link}
                  to="/products"
                  selected={matchPath({ path: '/products' }, pathname) !== null}
                >
                  <ListItemIcon>
                    <CategoryOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t(KeyTranslation.Document_Products)} />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/regions"
                  selected={matchPath({ path: '/regions' }, pathname) !== null}
                >
                  <ListItemIcon>
                    <SouthAmericaOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t(KeyTranslation.Organisation_Region)} />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/countries"
                  selected={matchPath({ path: '/countries' }, pathname) !== null}
                >
                  <ListItemIcon>
                    <PublicOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t(KeyTranslation.Common_Country)} />
                </ListItemButton>
                {/* <ListItemButton component={Link} to="/users" selected={matchPath({ path: '/users' }, pathname) !== null}>
                <ListItemIcon>
                  <GroupOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t('Menu_Users_management')} />
              </ListItemButton> */}
                {/* <ListItemButton component={Link} to="/roles" selected={matchPath({ path: '/roles' }, pathname) !== null}>
                <ListItemIcon>
                  <SecurityOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t('Menu_Roles_management')} />
              </ListItemButton> */}
                <ListItemButton
                  component={Link}
                  to="/documents"
                  selected={matchPath({ path: '/documents' }, pathname) !== null}
                >
                  <ListItemIcon>
                    <AssignmentOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t(KeyTranslation.Document_Documents)} />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/reportTemplate"
                  selected={
                    matchPath({ path: '/reportTemplate' }, pathname) !== null
                  }
                >
                  <ListItemIcon>
                    <AssessmentOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t(KeyTranslation.Menu_Report_Template)} />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/standards"
                  selected={matchPath({ path: '/standards' }, pathname) !== null}
                >
                  <ListItemIcon>
                    <DesignServicesOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Standard" />
                </ListItemButton>
              </>
            )}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: '#F4F4F8',
            flexGrow: 1,
            overflow: 'auto',
            position: 'relative'
          }}
        >
          <Toolbar />
          <Box p={5}>
            <Outlet />
          </Box>
        </Box>
      </Box>
      <Box className="footer">
        <Typography>{t(KeyTranslation.DataInput_Legal)}</Typography>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box display={'flex'} gap={2}>
            <Typography>{`© ${new Date().getFullYear()} ${t(KeyTranslation.Common_All_Rights_Reserved)}`}</Typography>
            <a
              href={
                'https://www.3m.com/3M/en_US/company-us/legal-information/'
              }
              target="_blank"
              rel="noreferrer"
            >
              {t(KeyTranslation.Common_Legal_Information)}
            </a>
            <a
              href={
                'https://www.3m.com/3M/en_US/company-us/privacy-policy/'
              }
              target="_blank"
              rel="noreferrer"
            >
              {t(KeyTranslation.Common_Privacy_Policy)}
            </a>
            <a href={'/TermsOfLicense.pdf'} target="_blank" rel="noreferrer">
              {t(KeyTranslation.Common_Legal_Of_License)}
            </a>
          </Box>
          <Typography>{process.env.REACT_APP_VERSION}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

const backtoLogin = (store: any): void => {
  if (store == null) {
    window.location.href = process.env.REACT_APP_LOGIN_URL ?? ''
  }
}

export default function Main (): JSX.Element {
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    const loginStore = localStorage.getItem('LOGIN')
    backtoLogin(loginStore)
    setLoading(false)
  }, [])
  return loading ? <></> : <MainContent />
}
