import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, Button, Modal } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { OAuthApi, UserApi } from '../../common/services'
import { omit } from 'lodash'
import localStorage from '../../common/localStorage'
import { ModalContent } from '../../common/components'

export default function Login (): JSX.Element {
  const [eulaModalOpen, setEulaModalOpen] = useState(false)
  const params = useParams()
  const navigate = useNavigate()
  const [hasScrolledToBottomOnce, setHasScrolledToBottomOnce] = useState(false)
  const [isLoading, setIsloading] = useState(false)

  const scrollBoxRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (params?.token != null) {
      new OAuthApi().apiV2OAuthGetUserInfoBySecurityCodeSecurityCodePost(params.token).then(({ data }) => {
        const user = omit(data.data, [
          'actions',
          'childCompanies',
          'modules',
          'services'
        ])
        localStorage.setItem('LOGIN', user)

        if ((data?.data?.termAndConditionAccepted) === false) {
          setEulaModalOpen(true)
        } else {
          navigate('/')
        }
      }).catch(() => {
      })
    }
  }, [params, navigate])

  const handleAcceptEula = async (): Promise<void> => {
    const values = {
      version: '1.0.0',
      languageAccepted: 'English'
    }
    try {
      setIsloading(true)
      const response = await new UserApi().apiV2UserTermAndConditionsPost(values)
      if (response.data.data === true) {
        setEulaModalOpen(false)
        navigate('/')
      } else {
        setEulaModalOpen(false)
        window.location.href = process.env.REACT_APP_LOGIN_URL ?? ''
      }
    } catch (ex: any) {
      console.log(ex)
    } finally {
      setIsloading(false)
    }
  }
  const handleClose = (): void => {
    setEulaModalOpen(false)
    window.location.href = process.env.REACT_APP_LOGIN_URL ?? ''
  }
  const handleScroll = (): void => {
    const scrollBox = scrollBoxRef.current
    if (scrollBox != null) {
      const isBottom = scrollBox.scrollTop + scrollBox.clientHeight >= scrollBox.scrollHeight - 5
      if (isBottom && !hasScrolledToBottomOnce) {
        setHasScrolledToBottomOnce(true)
      }
    }
  }
  return (
    <Box sx={{ width: '100vw', height: '100vh' }} display="flex" justifyContent="center" alignItems="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src={require('../../assets/images/3M_Active Safety_RGB_H43.png')} alt='logo' height={36} />
        <Typography variant='h3' mt={4}>Authenticating...</Typography>
      </Box>

      <Modal
        open={eulaModalOpen}
        onClose={() => { handleClose() }}
      >
        <ModalContent onClose={() => { handleClose() }} title={'Terms and Conditions'} width={860}>
          <Box
            sx={{ mt: 2, maxHeight: '300px', overflowY: 'auto' }}
            onScroll={handleScroll}
            ref={scrollBoxRef}
          >
            <Typography>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrs standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </Typography>
          </Box>
          <Box display="flex" justifyContent="end" gap={4} mt={4}>
            <Button onClick={() => { handleClose() }}>{'Decline'}</Button>
            <Button variant="contained" disabled={!hasScrolledToBottomOnce || isLoading} onClick={() => { void handleAcceptEula() }}>
              {'Accept'}
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  )
}
